*{
  box-sizing: border-box !important;
}

body {
  background: yellow !important;  /* luego en defintivo debe quedar purple */
  padding:0 !important; /* estoy probando con esta linea en el ano 2024, recomendacion=> dejarla prendida */
}
/* en 2024 le comenté la clase sinScrollXY ya que creo que nunca se usa */
/*
.sinScrollXY{
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background: yellow !important;
}
*/
/*FALTA: revisar */
/*estas tres clases de tipos de letra no funcionan (todas se ven igual... ) */
.letraRoboto{
  font-family:roboto !important;
}
.letraCourierNew{
  font-family:Courier !important;
}
.letraArial{
  font-family:Arial !important;
}

/* 100 90 10 18.btnPpal es el boton principal para los menus de lista de proformas, notas de entregam facturas.... */
.btnPpal{
  width:95px !important; /*estaba 100 */
  height:90px !important;
  margin-bottom:10px !important;
  font-size: 18px !important;  /* oligatorio !important. ESTE VALOR ES PARA EL TAMANO DEL TEXTO DEL BOTON */  
  background-color: #767676 !important;
  color:pink !important;
  padding:0 !important
}

.btnPpal:hover{
  /*background-color: rgb(40, 40, 40) !important; */
  background-color: #cbadb3  !important;  /* steelblue */
  color:purple !important; /* darkblue */
}

.btnBordebtnPpal{
  border: white 1px solid !important;
}

/* para pantallas pequenas */
@media (max-width:767px) {
  .textoBusquedaClientes{
     width:60% !important;
     background:cyan !important;
  }
  .anchoDatePickerGenerico{
    width:110px !important;
    /* background:cyan !important; */
    text-align: center !important;
  }  

  /* Clase para el tamano y alto del sweet cuando lo quiero en posicion */
  /* w:180  h:180 ok, sirve
  w:160  h:160 puede ser pero el circulo con la tile no quedaexactamente al centro
  w:170  h:160 esta me gusta (recomedable)
  */
  .classSweetPos{
    width:100px !important;  
    height:100px !important;
    font-size: 10px !important;
    background:moccasin !important;
    margin-bottom: 2% !important;
  }  

  .menuItemsVenta{
    width:46px !important; /* estaba 10% */
    height:46px !important;  /* estaba 47px */
    font-size:23px !important;    
    background-color: cyan !important;
    border-color: white !important;
    border-style: solid !important;
    border-width: thin !important;    
  }

  .btnOcultoPeq{
    display: none !important;
  }

  /* esta clase la uso al momento de facturar y son para 3 botones, van debajo de la cedula de cliente */
  .btnCliFact{
    width:33.33%;
    height:40px;
    border-style: solid !important;  
    border-color: black !important;  
    border-width: 1px !important;  
    border-radius: 6px !important;
    font-size: 14px !important;  
    text-align: center !important;
  }  
  .BotonMenuPpal2024variable{
    /* version ano 2024, para que sea mas responsive. Para controlar el ancho  */
    /* para pantallas pequenas */ 
    width:140px !important;
    font-size:0.8rem !important; /* para el texto de los iconos */
  }  
  .TamanoIconoMenuPpal2024{
    font-size:1rem !important; /* para el tamano del icono dentro del boton */
  }
}

/* esta clase la uso al momento de facturar y son para 3 botones. los uso para buscar un producto con el buscado,TAMBIEN PARA REFRESH, y PANEL DE DETALLES DEL PRODUCTO */
.btnGestorProductoFact{
  width:33.33%;
  height:40px;
  background-color: #303030 !important; /*  #303030 */
  border-style: solid !important;  
  border-color: black !important;  
  border-width: 1px !important;  
  border-radius: 6px !important;
  font-size: 14px !important;  
  text-align: center !important;
}  

/* pantallas grandes */
@media (min-width:768px) {
  .textoBusquedaClientes{
    width:35% !important;
    background:white !important;
  }  
  .anchoDatePickerGenerico{
    width:110px !important;
    /* background:white !important; */
    text-align: center !important;
  }
  /* Clase para el tamano y alto del sweet cuando lo quiero en posicion */
  .classSweetPos{
    width:120px !important;  
    height:120px !important;
    font-size: 12px !important;
    background:moccasin !important;
    margin-bottom: 1% !important;
  }    
  .menuItemsVenta{
    padding:0 !important;
    width:60px !important; /* estaba 50px */
    height:60px !important;    /* estaba 58px */    
    font-size:30px !important; /* estaba 29 , 33 */    
    background-color: #a2a2a2 !important;
    border-color: purple !important;
    border-style: solid !important;
    border-width: thin !important;
  }
  .btnCliFact{
    width:110px;
    height:40px;
    border-style: solid !important;  
    border-color: black !important;  
    border-width: 1px !important;  
    border-radius: 6px !important;
    font-size: 14px !important;  
    text-align: center !important;
  }    
/* esta clase la uso al momento de facturar y son para 3 botones. los uso para buscar un producto con el buscado,TAMBIEN PARA REFRESH, y PANEL DE DETALLES DEL PRODUCTO */
.btnGestorProductoFact{
  width:108px;
  height:40px;
  background-color: #303030 !important; /*  #303030 */
  border-style: solid !important;  
  border-color: black !important;  
  border-width: 1px !important;  
  border-radius: 6px !important;
  font-size: 14px !important;  
  text-align: center !important;
}  
.BotonMenuPpal2024variable{
  /* version ano 2024, para que sea mas responsive. Pero solo el ancho */
  /* para pantallas anchas */
  width:160px !important;
  font-size:1rem !important; /* para el texto de los iconos */
}  
  
.TamanoIconoMenuPpal2024{
  font-size:1.5rem !important; /* para el tamano del icono dentro del boton */
}
}

.btnCliFact:hover{
  background-color: rgb(40, 40, 40) !important;
  color:white !important;
}
.btnGestorProductoFact:hover{
  background-color:black !important;
  color:yellow !important;
}

.menuItemsVenta:hover{
  background-color:dimgray !important;
  }

/****** otros estilos **************/
.botonMeses{
  width:99%;
  margin-bottom:2%;  
}    

.botonMeses:hover {
  background-color: cyan !important;
  color:black !important;
}

/************************************************************************/
/**** estilos para sppiner ******/
/************************************************************************/
.spinnerDivPadre{
  /*creo estas 2 lineas no hacen nada */
  height: 100vh;
  position:relative;
}    
.spinnerDivHijo{
  margin:0;
  position:absolute;
  top:50%;
  left:50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}    
.spinnerEstilo{
  width:100px !important;
  height:100px !important;
  border: 15px solid currentColor !important;
  border-right-color: transparent !important;
}
.classLoaderGira{
  width:250px !important;  /* valor real/recomendable  250px */
  height:250px !important;
  /* originalmente el tamano de fuente era de 20px */
  font-size: 25px !important; 
  color:yellow !important;
}

/* clases para el formulario de Login*/
.classContainerLogin{
  background-color: purple;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  /* la imagen para el login siempre debe llamarse fondologin.jpg  (originalmente era: login2.jpg) ES OBLIGATORIO QUE SEA ORIGINALMENTE DEL TIPO JPG*/
  background-image:url(fondologin.jpg);  
  background-repeat:no-repeat;
  background-position:top left;
  background-size:cover; /* originalmente cover. valores:  [cover,contain]*/
} 

.classLogin{
  width: 350px;
  height: 440px;
  /*
  border:4px solid yellow;
  background-color: rgb(22, 21, 21);
   */
}  

/* clases para el Menu principal*/
.classContainerMenuPrincipal{
  /*
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  */
  width:100%; 
  min-height:100vh;
  padding-top:0px; /* antes estaba 10 */
  padding-bottom:0px; /* antes estaba 10 */
  background-color: gainsboro; /* purple */
  /* la imagen para el menu principal siempre debe llamarse fondomenu.png  (originalmente era: compradora1.png) ES OBLIGATORIO QUE ORIGINALMENTE SEA DEL TIPO PNG*/  
  /* a veces la munequita no se ve. ORIGINALMENTE sin comillas. Estoy probando con comillas a ver que tal*/
  background-image:url("fondomenu.png");  
  background-repeat:round; /* no-repeat, round */
  background-position:top left;
  background-size:cover; /* originalmente cover. valores:  [cover,contain]*/
} 
.classFooterMenuPpal{
  width:100%;
  /* height:16px !important;*/
  /* padding: 0; */
  text-align:center;
  line-height: 100%;
  position: absolute;
  bottom: 0;
}
.BotonMenuPpal{
  width:130px;
  height:95px;
  font-size:16px;  
  border: hotpink !important;
  border-width: 2px !important;
  border-style: solid !important;
  background:purple !important; /* purple */
  color:pink !important;
  border-radius: 10px !important;
}
.BotonMenuPpal2024fijo{
  /* version ano 2024, para que sea mas responsive */
  /* width:47% !important; el ancho lo controla el media query*/
  height:100% !important;
  /* font-size:1rem !important; /* para el texto de los iconos */
  border: hotpink !important;
  border-width: 2px !important;
  border-style: solid !important;
  background:purple !important; /* purple */
  color:pink !important;
  border-radius: 10px !important;
}
.BotonMenuPpalSalir{
  width:130px;
  height:95px;
  font-size:16px;  
  border: purple !important;
  border-width: 2px !important;
  border-style: solid !important;
  background:rgb(29, 29, 29) !important;
  color:white!important;
  border-radius: 10px !important;
}

.FondoCasiNegro{
  background:rgb(29, 29, 29) !important;
}

.AnchoFijo330px{
  width:330px !important;
}

/* ******************************* */
/* *** solo es para mostrar las credenciales de mi cliente en la parte superior del menu principal
/* puede ser datos completos en pantallas anchas. O solo el ruc en pantallas angostas */
/* ************************************* */
@media (max-width:880px) {
  .classLicenciadoCorto{
    display: block !important;
  }
  .classLicenciadoLargo{
    display: none !important;
  }
}
@media (min-width:881px) {
  .classLicenciadoCorto{
    display: none !important;
  }
  .classLicenciadoLargo{
    display: block !important;
  }
}

/* ******************************* */
/* *** solo es para quitar/poner el nombre comercial en el footer. Pero en el menu principal
/*  la idea es que siempre esté visible. Pero en alturas pequenas o cuando hago mucho zoom cercano, entonces que se quite */
/* ************************************* */
.classFooterMenuPpalVisibilidad{
  visibility: visible !important;
}

@media (max-height:460px) {
  .classFooterMenuPpalVisibilidad{
    visibility: hidden !important;
  }
}

/* esta clase es solo para hacer pruebas locas */
.pepemodal {
  background: red !important; 
margin-top: 200px !important;
  /* max-width: 90% !important; */
}

/********* COSAS SOBRANTES ***********/
/*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family:Helvetica,Futura,Arial,Verdana,sans-serif;
  
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*/